import { getRoles } from "../auth";
import Role from "../auth/permissions/Role";

window.dataLayer = window.dataLayer || [];

// User Dimension for Roles
const roles = getRoles();

if (roles) {
  window.dataLayer.push(
    { isAdmin: roles.includes(Role.ROLE_ADMIN) },
    { isBd: roles.includes(Role.ROLE_BD) },
    { isOp: roles.includes(Role.ROLE_OP) },
    { isUserManager: roles.includes(Role.ROLE_USER_MANAGER) },
    { isCapacityManager: roles.includes(Role.ROLE_CAPACITY_MANAGER) },
    { isGeneralSettings: roles.includes(Role.ROLE_GENERAL_SETTINGS) },
    { isPricing: roles.includes(Role.ROLE_PRICING) },
    { isScheduleManager: roles.includes(Role.ROLE_SCHEDULE_MANAGER) },
    { isReporting: roles.includes(Role.ROLE_REPORTING) },
    { isPartner: roles.includes(Role.ROLE_PARTNER) },
    { isPartnerLight: roles.includes(Role.ROLE_PARTNER_LIGHT) },
    { isStationManager: roles.includes(Role.ROLE_STATION_MANAGER) },
    { isPowerBi: roles.includes(Role.ROLE_POWER_BI) },
    { isDispatcher: roles.includes(Role.ROLE_DISPATCHER) },
    { isDriver: roles.includes(Role.ROLE_DRIVER) }
  );
}
